/****************************************************
************** General Stylesheet Rules *************
*****************************************************
* 4 Spaces Tab Indent
* large scope style blocks (e.g: organs, pages) are marked with 3 line star comments
* Smaller sub style blocks (e.g: molecules) are marked with a single line comment
* One line space between selectors inside nested selectors
* One line space between root level selectors
* Four lines space between logical style blocks
* Order style rules corresponding to the DOM order of the elements they're applied to
*
* Order for style properties:
* 1) display
* 2) position
* 3) box-model
* 4) color/font
* 5) others
*
* If not otherwise specified the properties are ordered in the following manner:
* from outside to inside of the element (margin, border, padding, width, height)
* from top to left (top, right, bottom, left)
* alphabetical
*/




/****************************************************
***************** Dev Support Styles ****************
****************************************************/
#header,
#hero,
#desktop-nav > div,
.fl-row-content,
.container,
main,
aside {
    //border: 1px solid $gray;
}




/****************************************************
************** Generic Element Styles ***************
****************************************************/
img {
    max-width: 100%;
    height: auto;

    // Prevent oversize bb-plugin lightbox portrait images
    &.mfp-img {
		max-height: 80vh !important;

        @media screen and (max-width: $screen-is-max) {
            margin-top: 2em;
            max-height: 65vh !important;
        }
    }
}

::selection {
    background: $color-primary;
    color:#FFF;
}

::-moz-selection {
    background: $color-primary;
    color:#FFF;
}

// Anchor element for scroll-top btn
#top {
    visibility: hidden;
    position: absolute;
    top: 0;
}

//Content Wrapper. Everything between header and footer
.content-wrap {
    .sidebar-primary.hero-area & {
        margin: 1em auto 2em;
    }

    .no-hero-area & {
      @media screen and (min-width: $screen-sm-min) {
        padding-top: 200px;
      }
    }
}




/****************************************************
*************** Global Content Styles ***************
****************************************************/
main {

    a {
        color:$color-primary;
        font-weight: 700;

        &:hover{
            color:lighten($color-primary, 10%)
        }
    }

    address {
        margin: 0;
    }

    blockquote {
      margin: 0;
      border: none;
      padding: 0;
      font-family: $font-family-serif;
      font-size: 1.2em;
      font-weight: 400;
      text-align: center;

      &::before {
        display: block;
        margin: 0 auto 0.25em auto;
        width: 2em;
        content: url("../images/favicon.svg");
        @media screen and (min-width: $screen-md) {
            width: 3.5em;
        }
      }
    }

    figure {

        figcaption {}
    }

    hr {}

    p {}

    .read-more {
        white-space: nowrap;

        i {
            display: inline-block;
            margin-top: -.25em;
            padding-left: .25em;
            font-size: 1em;
            vertical-align: middle;
        }
    }
}

.sticky {}

a[class^="sc-"] , span[class^="sc-"] {
    display: inline-block;
    color: inherit;
}

span[class^="sc-label"], .sc-zip {
    margin-right: .25em;
}

.sc-email {
    margin-bottom: .5em;
}

ol, ul {
    margin: 0;
    padding-left: 1.25em;

    li {
      margin-bottom: .25em;
    }

    &.styled-list {
        li {
            position: relative;
            padding: .5em 0;
            padding-left: 1.75em;
            list-style-position: inside;
            list-style: none;
            //background-image: url(../images/leaf-icon.svg);
            background-repeat: no-repeat;
            background-position: left .75em;
        }

    }
}


// Make absolute positioned modules editable. You have to set the class
.fl-builder-edit .absolute-fl-module .fl-module-content {
    top: 0 !important;
    margin-top: 0 !important;
}

// NO-js fallback for animated bb-plugin elements
.no-js {
    .fl-animation {
        opacity: 1 !important;
    }
}

// Fix width/height 1px on svg images in widgets
.widget {
    img {
        &.svg {
            width: 100%;
        }
    }
}

// Multi color stop seperator
.mc-sep-4 {
  .uabb-spacer-gap,
  .fl-separator {
    background: linear-gradient(to right, $color-quinary, $color-quinary 25%, $color-tertiary 25%, $color-tertiary 50%, $color-quaternary 50%, $color-quaternary 75%, $color-secondary 75%, $color-secondary 100%);
    border: none;
    height: 3px;
  }

  #hero & {
    background: linear-gradient(to right, $color-quinary, $color-quinary 25%, $color-tertiary 25%, $color-tertiary 50%, $color-quaternary 50%, $color-quaternary 75%, $color-secondary 75%, $color-secondary 100%);
    border: none;
    width: 100%;
    height: 6px;
  }
}

.mc-sep-5 {
  .uabb-spacer-gap,
  .fl-separator {
    background: linear-gradient(to right, $color-quinary, $color-quinary 20%, $color-tertiary 20%, $color-tertiary 40%, $color-quaternary 40%, $color-quaternary 60%, $color-secondary 60%, $color-secondary 80%, $color-primary 80%, $color-primary 100%);
    height: 3px;
    border: none
  }
}
