// Table styles
.table-centered {
    width: auto !important;
    margin: 0 auto;

    td {
        padding: 0 .5em;

        @media screen and (max-width: $screen-xs-max) {
            display: block;
            height: auto;
            padding: .5em 0;
        }
    }
}


.arrangement-table {
  &.tagungspauschalen {
    .table {
      &.table-striped {
        td,
        th {
          vertical-align: middle;
          text-align: center;

          &:first-child {
            text-align: left;
          }
        }

        @media screen and (max-width: $screen-is-max) {
          tbody {
            > tr {
              > td {
                white-space: normal;

                &:first-child {
                  font-size: $font-size-tiny;
                }
              }
            }
          }
        }

        tr:first-child {
          background: transparent;

          td {
            background: transparent;
          }
        }

        td:first-child {
          font-size: $font-size-small;
        }
      }
    }
  }

  .table {
    margin-bottom: .5em;

    &.table-striped {
      > tbody,
      > thead,
      > tfood {
        > tr {
          &:nth-of-type(odd) {
            background: transparent;

            td {
              background: $color-quinary-bg;
            }
          }

          td,
          th {
            border-top: none;
            padding: 4px;

            @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
              font-size: $font-size-small;
            }
          }

          th {
            font-weight: 400;
            line-height: 1.1;

            img.table-icon {
              display: block;
              max-height: 40px;
              width: auto;margin: 0;
              max-width: none;
              height: 40px;
            }

            small {
              display: block;
              margin-top: .25em;
            }
          }
        }
      }
    }
  }
}
