.fl-module-list-icon {
  &.justified-list {
    .fl-module-content {
      .uabb-list-icon {
        justify-content: space-between;

        .uabb-list-icon-wrap {
          flex-basis: calc(50% - 10px);

          @media screen and (max-width: $screen-sm-max) {
            font-size: $font-size-small;
          }

          @media screen and (min-width: $screen-sm-min) {
            flex-basis: calc(20% - 10px);
          }
        }
      }
    }
  }
}
