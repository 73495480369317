.fl-module-adv-testimonials {
  &.testimonial-slider {
    .fl-module-content {
      position: relative;
      padding: 1em 1.5em 1.5em;

      @media screen and (min-width: $screen-sm-min) {
        padding: 2em 3em 3em;
      }

      background: $color-quinary-bg;
      border: 6px solid $color-quinary-bg-light;
      border-radius: $border-radius-base;
      margin-bottom: 6em;

      &::before {
        display: block;
        position: absolute;
        bottom: -2.75em;
        left: calc(45% - 0.5em);
        height: 2.75em;
        border-top: none;
        border-right: 2.75em solid $color-quinary-bg-light;
        border-bottom: 2.75em solid transparent;
        border-left: none;
      }

      &::after {
        display: block;
        position: absolute;
        bottom: -2em;
        left: 45%;
        height: 2em;
        border-top: none;
        border-right: 2em solid $color-quinary-bg;
        border-bottom: 2em solid transparent;
        border-left: none;
      }

      .bx-wrapper {
        margin: 0 auto;

        .bx-controls {

          .bx-pager {
            bottom: -10em;

            &.bx-default-pager {
              a {
                width: 16px;
                height: 16px;
                border-radius: 100%;
              }
            }
          }
        }
      }

      .uabb-slider-prev,
      .uabb-slider-next {
        .fa {
          font-size: $font-size-base;
        }
      }

      .uabb-slider-prev {
        left: -15%;
      }

      .uabb-slider-next {
        right: -15%;
      }

      .uabb-testimonial {
        h3,
        h4 {
          text-transform: none;
        }

        .uabb-text-editor {

        }
      }
    }
  }
}
