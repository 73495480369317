    // .master-slider-parent, .master-slider, .ms-container, .ms-inner-controls-cont, .ms-slide-container, .ms-slide {
    //   height: 100%;
    // }
    .master-slider-parent {
        @extend .hero-area-content;

        .master-slider {
            z-index: 10;
            min-height: inherit;

            .ms-container,
            .ms-inner-controls-cont,
            .ms-view,
            .ms-slide,
            .ms-slide-container,
             {
              min-height: inherit;
            }

            img {
                max-width: none;
            }

            .ms-slide-info {
                @extend .centered-box;
                bottom: auto !important;
                margin: 0;
                padding: 0;
                min-height: initial;
                pointer-events: none;

                a, button {
                    pointer-events: all !important;
                    position: relative;
                }

                .ms-info {
                    padding: 1em;
                    background: rgba(255, 255, 255, 0.75);
                    text-align: center;

                    .cta-btn {
                      margin-top: 1em;
                    }

                    @media screen and (min-width: $screen-sm-min) {
                      max-width: 50%;

                      .cta-btn {
                        margin: 0 0 -4em 0;
                      }
                    }

                    @media screen and (min-width: $screen-md-min) {
                      max-width: 40%;
                    }
                }

                @media screen and (max-width: $screen-is-max) {
                    position: static;
                    transform: none;
                    max-width: 100%;

                    .ms-info {
                        padding: 0.5em;
                        font-size: 0.75em;
                    }
                }
            }
        }

        .ms-bullets {
            z-index: 20;
            width: 50px;

            @media screen and (min-width: $screen-sm-min) {
                bottom: 20px  !important;
            }

            .ms-bullet {
                width: 18px;
                height: 18px;
                border-radius: 100%;
                background: #fff;
            }
        }
    }
