.fl-module-button {
  .fl-module-content {
    box-shadow: none;

    .fl-button {
      border: none;
    }
  }

  &.box-shadow {
    .fl-button {
      box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
    }
  }

  &.no-button {
    .fl-button,
    .fl-button:visited {
      border-radius: 0px;
      padding: 0;
      background: transparent;
      border-bottom: 2px solid $text-color;
      font-weight: bolder;

      i.custom-icon {
        &::before {
          margin-right: -0.25em;
        }
      }
    }
  }
}
