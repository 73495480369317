body {
  font-size: $font-size-small;

  @media screen and (min-width: $screen-md-min) {
    font-size: $font-size-base;
  }
}

    h1,
    h2,
    h3,
    h4 {
      color: $color-primary;
      font-family: $font-family-serif;
      text-transform: uppercase;

      .signet-top & {
        &::before {
          display: block;
          margin: 0 auto 0.25em auto;
          width: 2em;
          @media screen and (min-width: $screen-md) {
            width: 3.5em;
          }
          content: url("../images/favicon.svg");
        }
      }
    }

    h1 {
      font-size: floor($font-size-h1*0.7);

      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        font-size: floor($font-size-h1*0.8);
      }

      @media screen and (min-width: $screen-md-min) {
        font-size: $font-size-h1;
      }

      + .tagline,
      + .uabb-subheading {
        font-size: floor($font-size-large*0.75);

        @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          font-size: floor($font-size-large*0.8);
        }

        @media screen and (min-width: $screen-md-min) {
          font-size: $font-size-large;
        }
      }
    }

    h2 {
      font-size: floor($font-size-h2*0.8);

      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        font-size: floor($font-size-h2*0.9);
      }

      @media screen and (min-width: $screen-md-min) {
        font-size: $font-size-h2;
      }

      + .tagline,
      + .uabb-subheading {
        font-size: $font-size-base;

        @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          font-size: floor($font-size-base*1.1);
        }

        @media screen and (min-width: $screen-md-min) {
          font-size: floor(($font-size-base * 1.15));
        }
      }
    }

    h3 {
      font-size: floor($font-size-h3*0.75);

      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        font-size: floor($font-size-h3*0.9);
      }

      @media screen and (min-width: $screen-md-min) {
        font-size: $font-size-h3;
      }
    }

    h4 {
      font-family: $font-family-sans-serif;
      font-weight: bold;
      text-transform: none;
    }

.slideshow-title,
.hero-title {
  color: $color-primary;
  font-family: $font-family-serif;
  text-transform: uppercase;
  font-size: $font-size-base;

  @media screen and (min-width: $screen-sm) {
    font-size: $font-size-large*0.9;
  }

  @media screen and (min-width: $screen-lg) {
    font-size: $font-size-h1*0.9;
  }
}

.tagline,
.uabb-subheading {
    color: $color-quinary;
    font-family: $font-family-serif;
}
