.fl-module-advanced-icon {
  &.logo-list {
    .fl-module-content {
      .adv-icon-wrap {
        &.adv-icon-horizontal {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          justify-content: space-around;

          .adv-icon-link {
            margin: 0;

            .uabb-image-content {
              padding: 0 .5em;
            }

            img {
              width: auto !important;
            }
          }
        }
      }
    }
  }
}
