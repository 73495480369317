#cookie-notice {

    .cookie-notice-container {
        font-size: $font-size-tiny;

        @media screen and (min-width: $screen-md) {
          font-size: $font-size-small;
        }

        #cn-notice-text {
            vertical-align: middle;

            @media screen and (max-width: $screen-xs-max) {
              display: block;
            }
        }

        a {
            @extend .cta-btn;
            font-size: inherit;
            text-transform: none;
            display: inline-block;
            margin: .5em 1em;
            max-width: 225px;
            padding: .25em .75em;
        }
    }
}
