#header {
    @extend .hidden-xs;
    @extend .hidden-is;

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 2px 6px 0 rgba(0,0,0,0.15);

    // First line of the website. Often contains contact information
    .header-contact {
        font-size: $font-size-small;

        .opening-times {
            @include make-sm-column(5);
        }

        .contact-infos {
            @include make-sm-column(7);

            ul {
                @extend .nav;
                @extend .nav-right;
                line-height: 1;

                li {
                    font-size: $font-size-small;

                    a,
                    i {
                        display: inline-block;
                        font-size: $font-size-small;
                        vertical-align: middle;

                        &::before {
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }

    // Wrapper for site logo and header widgets
    .header-content {
        padding: 1em 0;
        transition: all 300ms ease-in-out;

        .row {
          display: flex;
          align-items: center;
        }

        .site-logo {
            @include make-sm-column(3);
            @include make-md-column(4);

            .logo-link {
                display: block;
                height: 100px;
                background-color: $brand-primary;
            }

            .custom-logo-link {
              display: block;

              img {
                max-width: 100%;
                transition: all 300ms ease-in-out;
              }
            }
        }

        .header-widgets {
            @include make-sm-column(2);
        }

        .header-navigation {
            @include make-sm-column(9);
            @include make-md-column(8);

            .nav {
              .menu-item {
                font-size: $font-size-small;
                transition: all 300ms ease-in-out;

                a {
                  color: $color-primary;
                }

                @media screen and (min-width: $screen-md) {
                  font-size: $font-size-base;
                }
              }
            }

            ul {
                &.stacked-icons {
                    i {
                        display: block;
                        margin: 0;
                        text-align: center;
                    }
                }
            }
        }
    }

    // Header morphing styles
    &.affix {
      .header-content {
        padding: .5em 0 .25em 0;
      }

      .header-navigation {
        .nav {
          .menu-item {
            font-size: $font-size-small;
          }
        }
      }

      .site-logo {
        .custom-logo-link {
          img {
            max-width: 150px;
          }
        }
      }
    }
}
