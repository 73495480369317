.archive {
  &.tax-arrangement_category {
    .main {
      .row {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
      }
    }
  }

  .hentry {
      @include make-sm-column(12);
      @include make-sm-column(6);
      @include make-md-column(4);
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      text-align: center;

      .bypostauthor {}

      // Inner Wrap for post components
      .media {
          background: #fff;
          box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
          height: 100%;

          // Wrapper for post image
          .post-media {
              display: block;
              padding: 0;

              img {
                  width: 100%;
                  max-width: none;
              }
          }

          // Wrapper for post excerpt/content
          .media-body {
              padding: 1.5em;
              display: block;
              width: auto;

              .media-heading {
                margin-bottom: 0;

                @media screen and (min-width: $screen-sm) {
                  min-height: 2.5em;
                }

                a {
                  color: $color-quinary;
                  font-weight: 400;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }

              .arrangement-nights,
              .arrangement-price {
                font-size: $font-size-small;
                margin-top: .25em;

                @media screen and (min-width: $screen-sm) {
                  font-size: $font-size-base;
                }
              }

              .arrangement-nights {
                margin-bottom: 1em;

                @media screen and (min-width: $screen-sm) {
                  min-height: 2.5em;
                  margin-bottom: 1.5em;
                }
              }

              .arrangement-price {
                font-weight: 700;
                margin-bottom: 1em;
              }

              .read-more {
                background: $color-primary !important;

                &:hover {
                  background: $color-primary-lighten !important;
                }
              }
          }
      }
  }
}

// content-toggle for club arrangements

.uabb-rbs-toggle-sections {
  strong {
    color: $gray-dark;
  }
  ul {
    color: $gray;
    padding-left: 0;
    list-style-type: none;
    li {
      margin-bottom: .75em;
      padding-left: 1.5em;
      &:before {
        display: inline-block;
        content: '\e806';
        font-family: "custom-icons";
        padding-right: .5em;
        margin-left: -1.5em;
        color: $gray;
      }
    }
  }
  a.fl-button {
    background: $color-primary;
    color: #fff !important;
    border: 0;
    border-radius: 0;
    font-family: $font-family-serif;
    &:hover {
      background: $color-primary-lighten;
    }
    .fl-button-text {
      color: #fff !important;
    }
    .fl-button-icon {
      color: #fff !important;
    }
  }
  table {
    margin: 2em 0;
    span {
      font-size: 1.4rem;
    }
  }
}