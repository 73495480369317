.hero-image-wrap,
.empty-hero-image,
.header-post-thumbnail,
.header-default-image,
.header-no-image {
    @extend .hero-area-content;

    .archive & {
      min-height: 200px;
    }

    .hero-image {
        @include object-fit(cover);
        display: block;
        position: relative;
        width: 100%;
        min-height: inherit;
        max-height: inherit;
    }

    .hero-image-overlay {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: ' ';
    }
}
.hero-content-outer-wrap {
    min-height: inherit;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @media screen and (max-width: $screen-is-max) {
        position: static;
        min-height: auto;
    }

    .hero-content-inner-wrap {
      @include make-container-fixed();
      position: relative;
      min-height: inherit;

      .hero-content {
          @extend .centered-box;
          text-align: center;

          .content-top-left &,
          .content-top-right &,
          .content-center-left &,
          .content-center-right &,
          .content-bottom-left &,
          .content-bottom-right & {
              transform: none;

              @media screen and (min-width: $screen-sm-min) {
                  max-width: 50%;
              }

              @media screen and (min-width: $screen-md-min) {
                  max-width: 40%;
              }
          }

          .content-bottom-center &,
          .content-top-center & {
              transform: translateX(-50%) translateY(0);
          }

          .content-center-left &,
          .content-center-right & {
              transform: translateX(0) translateY(-50%);
          }


          .content-top-left & {
              top: 0;
              right: auto;
              left: 0;
          }

          .content-top-center & {
              top: 0;
              right: auto;
              left: 50%;
          }

          .content-top-right & {
              top: 0;
              right: 0;
              left: auto;
          }

          .content-center-left & {
              right: auto;
              left: 0;
          }

          .content-center-right & {
              right: 0;
              left: auto;
          }

          .content-bottom-left & {
              top: auto;
              right: auto;
              bottom: 0;
              left: 0;
          }

          .content-bottom-center & {
              top: auto;
              right: auto;
              bottom: 0;
              left: 50%;
          }

          .content-bottom-right & {
              top: auto;
              right: 0;
              bottom: 0;
              left: auto;
          }

          .hero-title {
            @extend .slideshow-title;
          }

          .hero-text {
          }

          .hero-cta {
              @extend %btn;
              @extend %cta-btn;
              @extend %cta-btn-hover;

              @media screen and (min-width: $screen-sm-min) {
                margin: 0 0 -1.5em 0;
              }
          }

          @media screen and (max-width: $screen-is-max) {
              position: static;
              transform: none !important;
              max-width: 100%;
              padding: 0.5em;
              font-size: 0.75em;
          }
      }
    }
  }

.header-no-image {
    background: $color-secondary;
    > div {
        @extend .container;
    }
    p {
        width: 100%;
        padding: 1em;
        color: #fff;
        text-align: center;

        @media screen and (min-width: $screen-sm-min) {
            font-size: 1.5em;
        }
    }
}

.hero-widgets-wrap {
  background: $color-primary;
  padding: .5em 0;

  .widget_caldera_forms_widget,
  .widget_custom_html {

    .custom-html-widget {
      @media screen and (min-width: $screen-sm) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    h3,
    h4 {
      color: #fff;
      margin: 0;
      padding-right: 1em;

      @media screen and (max-width: $screen-is-max) {
          font-size: $font-size-h3;
      }

      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          font-size: $font-size-h4*0.9;
      }
    }

    .ibe-toggle {
      cursor: pointer;

      i {
        display: none;
        transition: all 250ms ease-in-out;

        @media screen and (max-width: $screen-is-max) {
            display: inline-block;
        }
      }

      &.active {

        i {
          transform: rotate(45deg);
        }
      }
    }

    #form-booking {
      width: 100%;
      
      @media screen and (max-width: $screen-is-max) {
        height: 0;
        transition: all 250ms ease-in-out;
        visibility: hidden;

        &.form-open {
          padding-top: 1em;
          height: auto;
          visibility: visible;
        }
      }
    }

    form.form-inline {
      @media screen and (min-width: $screen-sm) {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
      }

      .form-group,
      .form-control {
        max-width: 100%;

        label {
          padding-right: .5em;
          color: #fff;
          font-size: $font-size-small;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

      .form-control {
        height: 36px;

        @media screen and (max-width: $screen-sm-max) {
            padding: 4px 8px;
        }

        &.days {
          margin-right: .5em;

          @media screen and (max-width: $screen-is-max) {
              margin-bottom: .25em;
          }
        }
      }

      div[class^="col-"] {
        @media screen and (max-width: $screen-sm-max) {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      .cta-btn {
        font-size: $font-size-small*0.9;
        padding: 7px 8px;

        @media screen and (min-width: $screen-md-min) {
            font-size: $font-size-small;
            padding: 6px 8px;
        }
      }
    }
  }
}
