/****************************************************
*************** Global Helper Classes ***************
****************************************************/

// Covered and centered background-image
.bg-img-cover {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

// vertically and horizontally centered box (absolute - relative to parent)
.centered-box {
    @extend .container;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (max-width: $screen-is-max) {
        width: 100%;
        max-width: 500px;
    }
}

.hero-area-content {
  position: relative;
  min-height: 200px;
  overflow: hidden;

  @media screen and (min-width: $screen-is) {
    min-height: 340px;
    max-height: 340px;
  }

  @media screen and (min-width: $screen-md) {
    min-height: 640px;
    max-height: 640px;
  }
}


// Horizontal aligned lists in content areas 50%/33.33%
.horizontal-list {
    @include list-unstyled();
    @include clearfix();

  li {
      background: $gray-lighter;
      padding: .5em;
      margin-bottom: 1em;

      @media screen and (min-width: $screen-is) {
          width: calc(50% - 15px);
          float: left;

          &:nth-child(odd) {
              clear: left;
          }

          &:nth-child(even) {
              margin-left: $grid-gutter-width;
          }
      }
  }

  .flexbox & {
      @media screen and (min-width: $screen-is) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          li {
              flex: 0 1 auto;
              flex-basis: calc(50% - 15px);
          }
      }
  }
}

.no-sidebar-primary {
  .horizontal-list {
      li {
          @media screen and (min-width: $screen-md) {
              float: left;
              width: calc(33.33% - 20px);
              margin-left: $grid-gutter-width;

              &:nth-child(odd) {
                  clear: none;
              }

              &:nth-child(3n + 1) {
                  margin-left: 0;
                  clear: left;
              }
          }
      }
  }

  .flexbox & {
      .horizontal-list {
          li {
              @media screen and (min-width: $screen-md) {
                  flex: 0 1 auto;
                  flex-basis: calc(33.33% - 20px);
              }
          }
      }
  }
}

// Set pipes after each element except the last one
.pipe-after {
    &::after {
        padding: 0 .5em;
        content: '|';
        vertical-align: top;
    }
    &:last-child {
        &::after {
            display: none;
        }
    }
}

// Set pipes before each element except the first one
.pipe-before {
    &::before {
        padding: 0 .5em;
        content: '|';
        vertical-align: top;
    }
    &:first-child {
        &::before {
            display: none;
        }
    }
}

// Separator list item created by piped walker
.pipe-separator {
    span {
        display: block;
        padding: $nav-link-padding 0;
    }
}

.dot-after {
    &::after {
        display: inline-block;
        padding: 0 .33em;
        content: '·';
        color: $color-primary;
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

// Horizontally justified list with no flexbox fallback
%justified-list {
    @include list-unstyled;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    font-size: 0;

    &::after {
        display: none;
    }

    > li {
        display: flex;
        flex-direction: column;
        flex: 0 1 auto;
        flex-grow: 2;
        text-align: center;

        &.pipe-separator {
            flex-grow: 0;
        }
    }



    .no-flexbox & {
        display: block;
        margin: 0;
        padding: 0;
        font-size: 0;
        text-align: justify;
        text-justify: distribute-all-lines; /* distribute items in IE */
        vertical-align: middle;
        zoom: 1;

        &::after {
            display: inline-block;
            width: 100%;
            content: '';
        }

        li {
            a {
                display: block;
            }
        }

        > li {
            display: inline-block;
            position: relative;
        }
    }
}

// Hide icon and show label in menu-items (by menu-icons plugin) (revert backend setting)
.icon-to-text {
    i,
    img._svg {
        display: none !important;
    }

    span.visuallyhidden {
        position: static;
        margin: auto;
        width: auto;
        height: auto;
        clip: auto;
        overflow: auto;
    }
}

.text-mobile {
    @media screen and (max-width: $screen-is-max) {
        i,
        img,
        img._svg {
            display: none !important;
        }

        span.visuallyhidden {
            position: static;
            margin: auto;
            width: auto;
            height: auto;
            clip: auto;
            overflow: auto;
        }
    }
}
.icon-mobile {
    @media screen and (max-width: $screen-is-max) {
        i,
        img,
        img._svg {
            display: block !important;
        }

        span.visuallyhidden {
            border: 0;
            position: absolute;
            margin: -1px;
            width: 1px;
            height: 1px;
            clip: rect(0 0 0 0);
            overflow: hidden;
        }
    }
}
