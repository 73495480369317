.fl-module-interactive-banner-1 {
  &.teaserbox-1-3 {
    .fl-module-content {
      .uabb-ib1-block {
        &.uabb-ib1-hover {
          .uabb-ib1-title {
            background: $color-quinary;

            &::before {
              background: rgba($color-quinary, 0.5);
            }
          }
        }

        .uabb-ib1-title {
          position: relative;
          transition: all 250ms ease-in-out;

          &::before {
            position: absolute;
            top: -0.5em;
            right: 0;
            bottom: auto;
            left: 0;
            height: 0.5em;
            display: block;
            content: "";
            background: rgba($color-primary, 0.5);
          }
        }
      }
    }
  }
}
