.archive {
  .page-header {
    text-align: center;
    margin: 0 auto;
  }

  &.archive-news {
    .hentry {
        @include make-sm-column(12);
        @include make-is-column(4);
        padding-top: 1.5em;
        padding-bottom: 1.5em;

        // @media screen and (min-width: $screen-is-min) and (max-width: $screen-is-max) {
        //     @include make-is-column(6);
        // }

        @media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
            border-bottom: 1px solid $brand-primary;
        }

        .bypostauthor {}

        // Inner Wrap for post components
        .media {
            background: #fff;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);

            // Wrapper for post image
            .post-media {
                @media screen and (max-width: $screen-xs-max) {
                    display: block;
                    padding: 0 0 .5em 0;
                }

                @media screen and (min-width: $screen-lg) {
                    display: block;
                    padding: 0;
                }

                img {

                    @media screen and (max-width: $screen-xs-max) {
                        width: 100%;
                        max-width: 100%;
                    }

                    @media screen and (min-width: $screen-is) {
                        max-width: 240px;
                    }

                    @media screen and (min-width: $screen-lg) {
                        max-width: 100%;
                    }
                }

            }

            // Wrapper for post excerpt/content
            .media-body {
                padding: 1em;

                @media screen and (max-width: $screen-xs-max) {
                    display: block;
                    width: auto;
                }

                @media screen and (min-width: $screen-is-min) and (max-width: $screen-is-max) {
                  padding: .5em;

                    .media-heading {
                      font-size: $font-size-base;
                    }
                }

                @media screen and (max-width: $screen-sm-max) {
                    font-size: $font-size-small;
                }

                @media screen and (min-width: $screen-lg) {
                    display: block;
                    width: auto;
                }
            }
        }
    }
  }
}
