/****************************************************
*************** General Footer Styles ***************
****************************************************/
#footer {}




/****************************************************
******************** Footer Menu ********************
****************************************************/
.footer-menu {
    @extend .hidden-xs;
    @extend .hidden-is;

    padding: 2em 0 4em;
    background: $gray;

    nav {
        @extend .container;

        #footer-nav {
            align-items: flex-start;

            // Generic #footer-nav list-item styles
            li {
                flex: 0 1 auto;
                font-size: $font-size-small;

                @media screen and (min-width: $screen-md-min) {
                  font-size: $font-size-base;
                }

                text-align: left;
                vertical-align: top;

                a {
                  color: #fff;
                }

                &.current-menu-item > a {
                    font-weight: bold;
                }
            }

            > li {
              > a {
                font-family: $font-family-serif;
                font-size: 1.1em;
                padding-bottom: 1.5em;
              }
                // First Level Sub-Menus
                > .sub-menu {
                    .menu-item a {
                        font-size: $font-size-small;
                        padding: .66em 0;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    }
                }

                &:last-child {
                  @media screen and (max-width: $screen-sm-max) {
                    display: none;
                  }
                }
            }
        }
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-social-links {
    @extend .container;
    padding: $grid-gutter-width/2 0;
    text-align: center;

    h4 {
        margin: 0 0 $grid-gutter-width/2;
    }

    .social-links-list {
        @extend .nav;
        @extend .nav-center;
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-widgets {
    @extend .container;
}




/****************************************************
******** Copyright Line (Last Line of site) *********
****************************************************/
.copyright {
    padding-bottom: 2.5em;

    .cookies-not-accepted & {
        padding-bottom: 5em;

        @media screen and (min-width: $screen-sm-min){
            padding-bottom: 3em
        }
    }
}

.copyright-row {
    @extend .row;
    padding-top: $grid-gutter-width/2;
    font-size: $font-size-small;

    ul li {
        @extend .pipe-before;
        float: left;

        @media screen and (max-width: $screen-is-max){
            float: none;

            &::after,
            &::before {
                display: none;
            }
        }
    }

    // Unternehmensname, Copyright, Adresse etc.
    .copyright-info {
        @extend .nav;
        @include make-sm-column(7);
        padding-bottom: $grid-gutter-width/2;
        text-align: center;

        @media screen and (min-width: $screen-sm-min){
            text-align: left;
        }

        li {
            margin: 0;
            font-size: $font-size-small;
        }
    }

    // Copyright Navigation (Kontakt, Impressum, Datenschutz etc.)
    .copyright-navigation {
        @include make-sm-column(5);
        padding-bottom: $grid-gutter-width/2;

        .menu {
            @include list-unstyled;
            float: right;

            @media screen and (max-width: $screen-is-max){
                float: none;

                li {
                    float: none;
                    display: block;
                    text-align: center;

                    a {
                      color: $text-color;
                    }
                }
            }
        }
    }

    .website-flat-sign {
      text-align: center;
    }
}
