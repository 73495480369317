.fl-module-blog-posts {
  &.uabb-blog-posts-grid, &.arrangement-teasers {
    .fl-module-content {
      .uabb-post-wrapper {

        .uabb-blog-post-inner-wrap {
          display: flex;
          flex-direction: column;
        }

        .uabb-blog-post-content {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          justify-content: space-between;

          .uabb-post-heading {
            min-height: 2em;
          }

          .uabb-blog-posts-description {
            padding-top: 1em;
            flex-grow: 1;
          }
        }
      }
    }

    .uabb-blog-posts-shadow {
      //display: flex;
      box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
    }
  }

  &.uabb-blog-posts-carousel, &.news-teaser {

    @media screen and (min-width: $screen-sm) {
      .uabb-post-thumbnail {
        width: 33.33%;
      }
      .uabb-blog-post-content {
        width: 66.66%
      }
    }
  }

  .uabb-text-editor {
    @media screen and (max-width: $screen-md-max) {
      font-size: $font-size-small;
    }
  }

  .uabb-read-more-text {
    .uabb-next-right-arrow {
      display: inline-block;
      color: transparent !important;

      &::after {
        display: inline-block;
        color: $text-color;
        content: '\e81c';
        font-family: 'custom-icons';
        margin-left: -0.5em;
        margin-bottom: -0.2em;
      }
    }
  }
}
