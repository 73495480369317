#caesar-data-booking-tool {

    label {
        width: 100%;
        color: #00305e;
        font-size: 21px;
    }

    input[type=text], select {	
        font-family: Verdana;
        font-size:13px;
        color: #00305e;
        min-width: 100px;
        margin: 10px;
        height:38px;
        padding-left:10px;
        border: 1px solid #cccccc;
        box-sizing:border-box;
    }

    .Div_QuickCheck {
        //display: flex;
        //flex-wrap: wrap;
        background-color: #fff;
        padding: 20px;
    }

    .Div_Input {
        flex-basis: 600px;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
    }

    .Div_InputRow {
        display: flex;
        flex-wrap: wrap;
        width:100%;
    }

    .Div_Continue {
        flex-basis: 300px;
        margin-top: 30px;
    }

    #Button_Continue {
        cursor: pointer;
        border: none;
        text-decoration: none;
        margin-bottom: 5px;
        word-wrap: break-word;
        white-space: -moz-pre-wrap;
        white-space: pre-wrap;
        border-radius: 0;
        -webkit-appearance: none;
        padding: 8px 18px;
        font-family: Marcellus, "Times New Roman", Times, serif;
        font-weight: 400;
        font-size: 18px;
        background-color:#6bb5d3;
        color: #ffffff;
        box-sizing: border-box;
        transition: 250ms all ease-in-out;
        vertical-align: middle;
        text-transform: uppercase;

        &:hover {
            background-color: #44a1c7;
        }
    }

        .Div_InputElement {
            flex-basis: 1px;
            flex-grow: 1;
            margin-bottom: 15px;
            margin-right: 15px;
            margin-top:10px;
            display: flex;
            flex-wrap: wrap;
            flex-flow: row wrap;
        }

        .Div_InputData {
            display: flex;
        }

        .Div_InputDataButtons {
            margin: 10px 10px;
            height: 38px;
            padding-left: 10px;
            padding-top: 5px;
            min-width: 200px;
        }

        .Div_InputDataButtons > label {
            font-size: 13px !important;
            margin-right:5px;
            display: inline;
        }

    label.Button_PlusMinus {
        background-color: white;
        font-size: 17px !important;
        border-bottom: 2px solid #ccc;
        display: inline-block;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;

        &:hover {
            background-color: #eee;
            cursor: pointer;
        }

    }

    .Arrival, .Departure, .NumberAdults {
        background-size: 25px;
        background-repeat: no-repeat;
        background-position-x: 5px;
        background-position-y: center;
        width: 35px;
        height: 60px;
        display: inline-block;
        float: left;
        margin-left:5px;
    }

    .Arrival, .Departure {
        background-image: url(https://ibe.hotels-online-buchen.de/Icons/Reservierung.png);
    }

    .NumberAdults {
        background-image: url(https://ibe.hotels-online-buchen.de/Icons/Personen.png);
    }

    .NumberChildren {
        background-size: 25px;
        background-repeat: no-repeat;
        background-position-x: 5px;
        background-position-y: center;
        width: 35px;
        height: 60px;
        display: inline-block;
        float: left;
        margin-left:5px;
        background-image: url(https://ibe.hotels-online-buchen.de/Icons/Babysitterservice.png);
    }
    
    .BookingCode {
        background-size: 25px;
        background-repeat: no-repeat;
        background-position-x: 5px;
        background-position-y: center;
        width: auto;
        line-height: 30px;
        display: inline-block;
        float: left;
        margin:5px;
        padding-left: 40px;
        background-image: url(https://ibe.hotels-online-buchen.de/Icons/Rabatte.png);
    }
}