#desktop-nav {
    @extend .hidden-xs;
    @extend .hidden-is;
    position: relative;
    z-index: 100;
    width: 100%;
    background: rgba(255, 255, 255, 0.75);

    nav {
        @extend .container;
    }

    // Generic Menu item Styles
    li {
      font-size: $font-size-small;

      @media screen and (min-width: $screen-lg-min) {
        font-size: $font-size-base;
      }

        a {
            padding: $nav-link-padding $nav-link-padding/2;
            color: $desktop-nav-link-color;
            transition: color 300ms ease-in-out;

            &:hover {
                color: $desktop-nav-link-hover-color;
                text-decoration: none;
            }
        }

        // Second Nav Level
        .sub-menu {
            display: none;
            background: $desktop-nav-bg;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);

            .current-menu-item,
            .current-menu-ancestor {
              &::after {
                display: none;
              }
                > a {
                  background: $color-senary-bg;
                }
            }

            @media screen and (min-width: $screen-sm-min) {
                display: block;
                visibility: hidden;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 10;
                min-width: 100%;
                transition: 0.1s 0.15s; /* delay of 0.15 seconds on hover off */
            }

            .menu-item {
              &:hover {
                background: $color-senary-bg;
              }
            }
        }
    }

    .affix & {
      li {
        a {
          padding: $nav-link-padding/2 $nav-link-padding/2;
        }
      }
    }

    // Current Menu Item Styles
    .current-menu-item,
    .current-menu-ancestor {
      &::after {
        display: block;
        content: "";
        height: 4px;
        background-color: $gray;
      }
        > a {

        }
    }

    .nav {
      // Remove padding from first & last top-level menu item
      > li {
        @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          flex-basis: 25%;

          > a {
            padding: $nav-link-padding/2;
          }
        }
      }

      > .menu-item-has-children {
        > a {
          &::after {
            display: inline-block;
            padding-left: .25em;
            font-family: "custom-icons";
            content: "\f107";
          }
        }
      }
    }

    // Menu item description
    .has-description {
        .menu-item-description {
            display: inline-block;
            margin-right: .25em;
            width: 1em;
            vertical-align: middle;
            line-height: 1;

            img {
                display: inline-block;
                width: auto;
                height: auto;
                vertical-align: initial;
            }

            i {
                margin-top: -.265em;
            }
        }
    }
}




// Open First Level Sub-Menus of the last item to the left
#desktop-nav {
    nav {
        @media screen and (min-width: $screen-sm-min) {
            > ul {
                > li:last-child {
                    .sub-menu {
                        left: auto;
                        right: 0;

                        // Open Second Level Sub-Menus of the last item to the left
                        .sub-menu {
                            right: 100%;
                        }
                    }
                }
            }

            li:hover {
                > .sub-menu {
                    visibility: visible;
                    transition-delay: 0s;
                }
            }
        }
    }
}


// Third Nav Level
#desktop-nav {
    .sub-menu {
      .menu-item-has-children {
        > a {
          white-space: nowrap;

          &::after {
            display: inline-block;
            padding-left: .5em;
            font-family: "custom-icons";
            font-size: 0.66em;
            content: "\e81f";
            vertical-align: baseline;
          }
        }
      }
        .sub-menu {
            @media screen and (min-width: $screen-sm-min) {
                top: 0;
                left: 100%;
            }
        }
    }
}
