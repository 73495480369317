.search-results {
    article {
        .entry-wrap {
            @include make-row();

            .entry-img {
                @include make-is-column(3);
                text-align: center;

                .entry-img-link {
                    display: block;

                    &:empty {
                        min-height: 125px;
                        width: 100%;
                        background: $gray-light;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center center;
                        opacity: 0.33;
                    }
                }

                img {
                    width: auto;
                    max-height: 200px;
                }
            }

            .entry-content {
                @include make-is-column(9);
                .entry-title {
                    margin-top: 0;
                }
            }

            .spacer {
                @include make-is-column(12);

                hr {
                    margin: .75em 0 1em;
                }
            }
        }

        &.has-post-thumbnail {
            .entry-content {
                //@include make-is-column(9);
            }
        }
    }

    .posts-navigation {
        @include make-row();

        .nav-links > div {
            @include make-is-column(6);

            a {
                text-decoration: none;
            }

            &.nav-previous {
                float: right;
                text-align: right;

                a {
                    &::after {
                        content: '\2192';
                        padding-left: .25em;
                    }
                }
            }
            &.nav-next {
                a {
                    &::before {
                        content: '\2190';
                        padding-right: .25em;
                    }
                }
            }
        }


    }
}
