.fl-module-post-grid {
    .fl-module-content {
        .fl-post-feed {
            .fl-post-feed-post {
                max-width: none;
            }
            .fl-post-feed-header {
                margin-bottom: 0;
            }
        }

        .fl-post-grid {
          text-align: center;

          .fl-post-grid-post {
            box-shadow: 1px 2px 8px 0 rgba(0,0,0,0.15);

            .fl-post-grid-title {
              margin-bottom: 0;

              @media screen and (max-width: $screen-xs-max) {
                font-size: $font-size-base;
              }

              @media screen and (min-width: $screen-sm) {
                min-height: 2.5em;
              }

              a {
                font-weight: 400;
              }
            }

            .fl-post-grid-more {
              @extend %btn;

              background: $color-primary;
              box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
              color: #fff;

              &::after {
                display: inline-block;
                content: '\e81c';
                font-family: 'custom-icons';
                padding-left: .5em;
              }

              &:hover {
                background: $color-primary-lighten;
              }
            }
          }
        }
    }
}

// Global style scope. Also used in uabb-blog-posts module
.arrangement-nights,
.arrangement-price {
  font-size: $font-size-small;
  margin-top: .25em;

  @media screen and (min-width: $screen-sm) {
    font-size: $font-size-base;
  }
}

.arrangement-nights {
  margin-bottom: 1em;

  @media screen and (min-width: $screen-sm) {
    min-height: 2.5em;
    margin-bottom: 1.5em;
  }
}

.arrangement-price {
  font-weight: 700;
  margin-bottom: .5em;
}
