.bottombar {
    display: flex;
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    box-shadow: 0px -2px 6px 0 rgba(0,0,0,0.15);
    z-index: 9999;

    .nav {
        border-top: 1px solid $gray-lighter;

        li {
            margin-bottom: 0;
            padding: 4px 0;
            font-size: $font-size-large;
            border-right: 1px solid $gray-lighter;

            &:last-child {
                border: none;
            }

            i {
                font-size: inherit;
            }
        }
    }
}

.page-wrap {
    @media screen and (max-width: $screen-xs-max) {
        padding-bottom: 2.5em;
    }
}
